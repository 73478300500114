@font-face {
  font-family: "AppFont";
  src: url("./assets/font/trebuc.ttf");
}

body {
  font-family: "AppFont" !important;
}

.appPadding {
  padding: 20px;
}

.ac {
  text-align: center;
}

.al {
  text-align: left;
}

.ar {
  text-align: right;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appLogoPrivacy {
  width: 200px;
  height: auto;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.ml10 {
  margin-left: 10px;
}

.pd20 {
  padding-top: 20px;
}
.emailF {
  padding: 6px 10px;
  max-width: 300px;
  width: 100%;
  border-radius: 6px;
}

.mySpineer {
  position: absolute;
  background: #0d0d0d26;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorBox {
  text-align: left;
  color: red;
  margin-left: 1%;
  margin-top: 3px;
  margin-bottom: 15px;
  font-size: 14px;
}

.otpSent {
  color: green;
  font-size: 14px;
  margin-bottom: 5px;
}

.labelDelete {
  color: #353535;
  font-size: 12px;
  margin-bottom: 5px;
}
